import { LocalizationService } from '@abp/ng.core';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ca-customer-sentiment-icon',
  templateUrl: './customer-sentiment-icon.component.html',
  styleUrls: ['./customer-sentiment-icon.component.scss', '../conversation-icons.scss'],
})
export class CustomerSentimentIconComponent implements OnInit {
  @Input()
  score: number | null;

  get iconCls(): string {
    if (this.score > 0) {
      return 'far fa-smile';
    } else if (this.score == 0) {
      return 'far fa-meh';
    } else if (this.score < 0) {
      return 'far fa-frown';
    }
  }

  get tooltip(): string {
    if (this.score > 0) {
      return (
        this.localizationService.instant('Conversation::CustomerSentimentTooltip') +
        this.localizationService.instant('Conversation::CustomerSentiment:Positive')
      );
    } else if (this.score == 0) {
      return (
        this.localizationService.instant('Conversation::CustomerSentimentTooltip') +
        this.localizationService.instant('Conversation::CustomerSentiment:Neutral')
      );
    } else if (this.score <0) {
      return (
        this.localizationService.instant('Conversation::CustomerSentimentTooltip') +
        this.localizationService.instant('Conversation::CustomerSentiment:Negative')
      );
    }
  }

  constructor(private localizationService: LocalizationService) {}

  ngOnInit(): void {}
}
