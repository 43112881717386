import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  ViewChild,
  ElementRef,
  forwardRef,
} from '@angular/core';
import { DropdownSelectorDatasourceModel } from '../../models/dropdown-selector-datasource.model';
import { DropdownSelectorItemModel } from '../../models/dropdown-selector-item.model';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ListResponseDto } from 'src/core/models/request/list-response.dto';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';
import { CrudService } from 'src/core/services/crud/crud.service';

@Component({
  selector: 'ca-dropdown-selector',
  templateUrl: './dropdown-selector.component.html',
  styleUrls: ['./dropdown-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DropdownSelectorComponent),
    },
  ],
})
export class DropdownSelectorComponent implements OnInit, ControlValueAccessor {
  isRTL: boolean;

  @BlockUI() blockUI: NgBlockUI;

  @Input()
  showDropdownIcon = false;

  @Output()
  changed = new EventEmitter();

  @Output()
  openChanged = new EventEmitter<boolean>();

  @ViewChild('edtSearch', { static: true })
  edtSearch: ElementRef;

  @ViewChild('selectorDropdown', { static: true })
  selectorDropdown: NgbDropdown;

  private _datasource: DropdownSelectorDatasourceModel = {
    data: [],
    totalCount: 0,
  };
  private _pageSize = 10;
  private _currentPage = 1;
  private _pageCount = 0;
  private cache: any[] = [];
  private _disabled = false;
  private _emptyText: string;
  private _filters: FilterItemDto[] | string;
  private _sorters: SorterItemDto[] | string;

  forceReload = false;
  items: DropdownSelectorItemModel[] = [];
  selected: any[] = [];
  queryValue = '';
  currentPageInputValue: number;

  @Input()
  filterProperty = 'filters';

  @Input()
  sorterProperty = 'sorters';

  @Input()
  hasPaging: boolean = true;

  @Input()
  hideHeader: boolean = false;

  @Input()
  dropdownIconClass: string = 'far fa-ballot-check';

  @Input()
  dropdownClass: string = 'ca-dropdown-menu';

  @Input()
  skipCountProperty = 'skipCount';

  @Input()
  maxResultCountProperty = 'maxResultCount';

  @Input()
  multiple = false;

  @Input()
  returnValueAsArray = true;

  @Input()
  maxSelectionCount?: number;

  @Input()
  selectorPosition: string = 'Right';
  @Input()
  information: string;

  @Input()
  styles: any = {};

  @Input()
  listStyles: any = {};

  @Input()
  set emptyText(value: string) {
    this._emptyText = value;
  }

  get emptyText(): string {
    return this._emptyText;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  buttonCls: string;

  @Input()
  labelCls: string;

  @Input()
  url: string;

  @Input()
  idProperty = 'id';

  @Input()
  disabledProperty: string;

  @Input()
  disabledValue: boolean = true;

  @Input()
  itemTemplate: TemplateRef<any>;

  @Input()
  selectionTemplate: TemplateRef<any>;

  @Input()
  listSelectionTemplate: TemplateRef<any>;

  @Input()
  filterToolbarTemplate: TemplateRef<any>;

  @Input()
  queryOperator: number;

  @Input()
  queryField: string;

  @Input()
  useSimpleFilter: boolean = false;

  @Input()
  useSimpleSorter: boolean = false;

  @Input()
  useComboboxLook: boolean = false;

  @Input()
  externalDatasource: DropdownSelectorDatasourceModel;

  set datasource(d: DropdownSelectorDatasourceModel) {
    this._datasource = d;
  }

  get datasource(): DropdownSelectorDatasourceModel {
    return this._datasource;
  }

  get totalCount(): number {
    return this._datasource ? this._datasource.totalCount : 0;
  }

  @Input()
  set pageSize(p: number) {
    this._pageSize = p;
  }

  get pageSize(): number {
    return this._pageSize;
  }

  @Input()
  set currentPage(p: number) {
    this._currentPage = p;
    this.currentPageInputValue = p;

    this.load();
  }

  get currentPage(): number {
    return this._currentPage;
  }

  get pageCount(): number {
    return this._pageCount;
  }

  get lowerBound(): number {
    return this._currentPage === 1 ? 1 : (this._currentPage - 1) * this._pageSize + 1;
  }

  get upperBound(): number {
    return this._currentPage === this._pageCount
      ? this.totalCount
      : this._pageSize * this._currentPage;
  }

  get value(): any | any[] {
    if (this.returnValueAsArray === false && this.multiple === false) {
      return this.selected.length === 0 ? null : this.selected[0];
    }
    return this.selected;
  }

  get valueAsArray(): any[] {
    return this.selected;
  }

  @Input()
  set filters(value: FilterItemDto[] | string) {
    this._filters = value;
    this.forceReload = true;
  }

  @Input()
  set sorters(value: SorterItemDto[] | string) {
    this._sorters = value;
    this.forceReload = true;
  }

  private calculatePageCount() {
    this._pageCount = Math.ceil(this.totalCount / this.pageSize);
  }

  private setItems() {
    this.items = [];

    if (!this._datasource) {
      return;
    }

    this._datasource.data.forEach(element => {
      let newItem = {
        data: element,
        selected: false,
        disabled: this.checkDisability(element),
      };

      this.items.push(newItem);
    });
  }

  private setSelected() {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.selected.length; i++) {
      // tslint:disable-next-line: prefer-for-of
      for (let j = 0; j < this.items.length; j++) {
        if (this.selected[i][this.idProperty] === this.items[j].data[this.idProperty]) {
          this.items[j].selected = true;
          break;
        }
      }
    }

    this.onChange(this.value);
    this.changed.emit();
  }

  private getSorters(): SorterItemDto[] | string {
    if (typeof this._sorters === 'string') {
      return this._sorters;
    }

    let result: SorterItemDto[] = [];
    if (this._sorters && this._sorters.length > 0) {
      result = result.concat(this._sorters);
    }

    return result;
  }
  private checkDisability(data: any): boolean {
    if (
      this.disabledProperty &&
      data[this.disabledProperty] !== null &&
      data[this.disabledProperty] === this.disabledValue
    ) {
      return true;
    } else {
      return false;
    }
  }
  public setFilters(filters: FilterItemDto[]) {
    this._filters = filters;
  }
  public getFilters(): FilterItemDto[] | string {
    if (typeof this._filters === 'string') {
      return this.queryValue;
    }

    let result: FilterItemDto[] = [];
    if (this._filters && this._filters.length > 0) {
      result = result.concat(this._filters);
    }

    if (this.queryValue && this.queryValue.trim().length > 0) {
      result.push({
        field: this.queryField,
        operator: this.queryOperator,
        value: this.queryValue,
      });
    }

    return result;
  }

  refresh() {
    this.forceReload = true;
    this.load();
  }

  load() {
    if (this.forceReload) {
      this.cache = [];
    }

    if (this.cache[this._currentPage] && !this.forceReload) {
      this._datasource = this.cache[this._currentPage];

      this.setItems();
      this.calculatePageCount();
      this.setSelected();

      return;
    }

    this.forceReload = false;

    const filters = this.getFilters();
    const sorters = this.getSorters();

    let params = new HttpParams();

    if (typeof filters === 'string') {
      params = params.append(this.filterProperty, filters);
    } else {
      params = params.append(this.filterProperty, JSON.stringify(filters));
    }
    if (typeof sorters === 'string') {
      params = params.append(this.sorterProperty, sorters);
    } else {
      params = params.append(this.sorterProperty, JSON.stringify(sorters));
    }

    params = params.append(
      this.skipCountProperty,
      JSON.stringify(this._pageSize * (this._currentPage - 1))
    );
    params = params.append(this.maxResultCountProperty, JSON.stringify(this._pageSize));

    if (this.url) {
      const req = this.crudService.http.get(this.url, {
        params,
      }) as Observable<ListResponseDto<any>>;

      this.blockUI.start(this.localizationService.instant('AbpIdentity::LoadingWithThreeDot'));
      req.subscribe(response => {
        this.datasource = {
          data: response.items,
          totalCount: response.totalCount,
        };

        this.cache[this._currentPage] = this._datasource;

        this.setItems();
        this.calculatePageCount();
        this.setSelected();
        this.blockUI.stop();
      });
    } else if (this.externalDatasource) {
      this.datasource = {
        data: this.externalDatasource.data,
        totalCount: this.externalDatasource.totalCount,
      };

      this.cache[this._currentPage] = this._datasource;

      this.setItems();
      this.calculatePageCount();
      this.setSelected();
      this.blockUI.stop();
    }
  }

  private clearSelection() {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.items.length; i++) {
      this.items[i].selected = false;
    }

    this.selected = [];
  }

  changeSelection(item: DropdownSelectorItemModel, fromInput: boolean) {
    if (!item.selected) {
      if (!this.multiple) {
        this.clearSelection();
      }
      this.selected.push(item.data);
    } else {
      let idx = -1;
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.selected.length; i++) {
        if (this.selected[i][this.idProperty] === item.data[this.idProperty]) {
          idx = i;
          break;
        }
      }

      this.selected.splice(idx, 1);
    }

    item.selected = !item.selected;
    this.onChange(this.value);
    this.changed.emit();
  }

  onOpenChanged(opened) {
    this.openChanged.emit(opened);
    if (opened) {
      this.currentPage = 1;

      setTimeout(() => {
        this.edtSearch.nativeElement.focus();
      }, 250);
    }
  }

  onNextPageClick(eventArgs: MouseEvent) {
    if (this._currentPage < this._pageCount) {
      this.currentPage++;
    }
  }

  onLastPageClick(eventArgs: MouseEvent) {
    if (this._currentPage !== this._pageCount) {
      this.currentPage = this._pageCount;
    }
  }

  onPreviousPageClick(eventArgs: MouseEvent) {
    if (this._currentPage > 1) {
      this.currentPage--;
    }
  }

  onFirstPageClick(eventArgs: MouseEvent) {
    if (this._currentPage !== 1) {
      this.currentPage = 1;
    }
  }

  onClickRemoveItem(eventArgs, item) {
    const idx = this.selected.indexOf(item);

    this.selected.splice(idx, 1);

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.items.length; i++) {
      if (item[this.idProperty] === this.items[i].data[this.idProperty]) {
        this.items[i].selected = false;
        break;
      }
    }

    this.onChange(this.value);
    this.changed.emit();
  }

  onClearSelectionClick(eventArgs) {
    this.clearSelection();
    this.onChange(this.value);
    this.changed.emit();
  }

  onItemClick(eventArgs, item: DropdownSelectorItemModel) {
    if (!this.checkItemDisability(item)) {
      const fromInput = eventArgs.srcElement.checked !== undefined;
      let tempSelected = item.selected;

      this.changeSelection(item, fromInput);

      if (!this.multiple && !tempSelected) {
        this.selectorDropdown.close();
      }
    }
  }

  onSearchRequest(eventArgs: KeyboardEvent) {
    this.forceReload = true;
    this.currentPage = 1;

    eventArgs.preventDefault();
    eventArgs.stopPropagation();

    return false;
  }

  onRemoveFilterClick(eventArgs: MouseEvent) {
    this.queryValue = '';
    this.forceReload = true;
    this.currentPage = 1;
  }

  writeValue(obj: any): void {
    this.queryValue = '';
    this.forceReload = true;
    if (Array.isArray(obj)) {
      this.selected = obj;
    } else if (obj != null) {
      this.selected = [obj];
    } else {
      this.selected = [];
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChange(selection: any[]) {}

  onTouched() {}

  onPageInputChange(eventArgs: KeyboardEvent) {
    if (isNaN(this.currentPageInputValue)) {
      this.currentPage = 1;
    } else if (this.currentPageInputValue < 1) {
      this.currentPage = 1;
    } else if (this.currentPageInputValue > this._pageCount) {
      this.currentPage = this._pageCount;
    } else {
      this.currentPage = this.currentPageInputValue;
    }
  }

  checkItemDisability(item) {
    let selectedCount = this.selected.length;
    if (
      item.disabled ||
      (this.maxSelectionCount != null && selectedCount >= this.maxSelectionCount)
    ) {
      return true;
    } else {
      return false;
    }
  }

  constructor(
    private crudService: CrudService,
    private operators: Operators,
    private config: ConfigStateService,
    private localizationService: LocalizationService
  ) {}

  ngOnInit() {
    this.buttonCls = this.buttonCls ? this.buttonCls : 'btn btn-outline-primary btn-sm';
    const localize = this.config.getOne('localization');
    this.isRTL = localize?.currentCulture.cultureName === 'ar';
  }
}
