import { Api } from "src/core/decorators/dto/api.decorator";
import { DtoHasApi } from "../../shared/dto-has-api.model";

@Api('api/app/phrase-type')
export class PhraseTypeDto extends DtoHasApi {
    id: number;
    name: string;
    supportedLanguages: string;
    isUsedInRecognition: boolean;
    isEnabled: boolean;
}