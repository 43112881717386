import { Api } from 'src/core/decorators/dto/api.decorator';
import { SimpleConversationDto } from '../../conversation/simple-conversation.dto';
import { FormVersionDto } from '../../quality-management/form-version.dto';
import { DtoHasApi } from '../../shared/dto-has-api.model';
import { AssignmentDto } from 'src/core/models/quality-management/assignment.dto';
import { EvaluationDto } from './evaluation.dto';
import { AutomaticAssignmentDto } from '../../quality-management/automatic-assignment.dto';
import { UserDto } from '../../shared/user.dto';

@Api('api/app/evaluation')
export class EvaluationMasterDto extends DtoHasApi {
  id: number;
  formId: number;
  assignmentId: number;
  automaticAssignmentId: number;
  formVersionId: number;
  conversationId?: number;
  userId?: string;
  label: string;
  evaluationPoint: number;
  maxPoint: number;
  assignmentName: string;
  creationTime: Date;
  lastModificationTime: Date;
  assignment: AssignmentDto;
  automaticAssignment: AutomaticAssignmentDto;
  formVersion: FormVersionDto;
  conversation: SimpleConversationDto;
  user: UserDto;
  evaluations: EvaluationDto[];

  constructor(init?: Partial<EvaluationMasterDto>) {
    super();

    if (init) {
      Object.assign(this, init);
    }
  }
}
