import { LocalizationService } from '@abp/ng.core';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';
import { ConversationMarkDto } from 'src/core/models/mark/conversation-mark.dto';

@Component({
  selector: 'ca-player-timeline-sentiment-layer',
  templateUrl: './player-timeline-sentiment-layer.component.html',
  styleUrls: ['./player-timeline-sentiment-layer.component.scss'],
})
export class PlayerTimelineSentimentLayerComponent implements OnInit, OnDestroy {
  @ViewChild('sentimentCanvas') sentimentCanvas: ElementRef<HTMLCanvasElement>;

  sentimentChart: Chart;
  sentimentWidth: any;
  sentimentHeight: any;
  gradient: any;
  constructor(private localizationService: LocalizationService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.sentimentChart?.destroy();
  }

  getGradient(ctx, chartArea, self) {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (
      !self.gradient ||
      self.sentimentWidth !== chartWidth ||
      self.sentimentHeight !== chartHeight
    ) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      self.sentimentWidth = chartWidth;
      self.sentimentHeight = chartHeight;
      self.gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
      self.gradient.addColorStop(0, '#EE0883');
      self.gradient.addColorStop(0.1, '#E312AC');
      self.gradient.addColorStop(0.2, '#C523A2');
      self.gradient.addColorStop(0.3, '#AF3D96');
      self.gradient.addColorStop(0.4, '#9c65a3');
      self.gradient.addColorStop(0.5, '#738db4');
      self.gradient.addColorStop(0.6, '#4bb4c5');
      self.gradient.addColorStop(0.7, '#2bd4d4');
      self.gradient.addColorStop(0.8, '#0df1e4');
      self.gradient.addColorStop(0.9, '#05f9eb');
      self.gradient.addColorStop(1, '#00dcff');
    }

    return self.gradient;
  }

  fillGaps(records: ConversationMarkDto[], duration: number): ConversationMarkDto[] {
    if (records.length < 2) {
      return records;
    }

    const filledRecords: ConversationMarkDto[] = [];
    for (let i = 0; i < records.length - 1; i++) {
      const currentRecord = records[i];
      const nextRecord = records[i + 1];
      filledRecords.push(currentRecord);
      const gap = nextRecord.startMillisecond - currentRecord.endMillisecond;
      if (gap > 0) {
        const startMillisecond =
          gap > 2000 ? currentRecord.endMillisecond + 1000 : currentRecord.endMillisecond + gap / 2;
        const endMillisecond =
          gap > 2000 ? nextRecord.startMillisecond - 1000 : nextRecord.startMillisecond - gap / 2;
        const newRecord: ConversationMarkDto = {
          callId: currentRecord.callId,
          startMillisecond: startMillisecond,
          endMillisecond: endMillisecond,
          value: 0,
          type: currentRecord.type,
          channel: currentRecord.channel,
        };

        filledRecords.push(newRecord);
      }
    }

    const lastRecord = records[records.length - 1];
    const gap = duration - lastRecord.endMillisecond;
    filledRecords.push(lastRecord);

    if (gap > 0) {
      const startMillisecond =
        gap > 2000 ? lastRecord.endMillisecond + 1000 : lastRecord.endMillisecond + gap / 2;
      const endMillisecond = gap > 2000 ? duration - 1000 : duration - gap / 2;
      const newRecord: ConversationMarkDto = {
        callId: lastRecord.callId,
        startMillisecond: startMillisecond,
        endMillisecond: endMillisecond,
        value: 0,
        type: lastRecord.type,
        channel: lastRecord.channel,
      };
      filledRecords.push(newRecord);
    }
    return filledRecords;
  }

  initializeLayer(conversationMarks: ConversationMarkDto[], duration: number) {
    let chartData = [];
    let durationMS = duration * 1000;
    if (conversationMarks) {
      if (conversationMarks.length > 0) {
        conversationMarks = conversationMarks.sort((a, b) => {
          return new Date(a.startMillisecond).getTime() - new Date(b.startMillisecond).getTime();
        });

        conversationMarks = this.fillGaps(conversationMarks, durationMS);

        chartData.push({ x: 0, y: 0 });
        conversationMarks.forEach(mark => {
          if (chartData.length === 0) {
            chartData.push({ x: 0, y: 0 });
            chartData.push({ x: mark.startMillisecond - 1, y: 0 });
          }

          chartData.push({ x: mark.startMillisecond, y: mark.value });
          chartData.push({ x: mark.endMillisecond, y: mark.value });
        });
      }

      chartData.push({ x: durationMS, y: 0 });

      let self = this;
      if (this.sentimentChart) {
        this.sentimentChart?.destroy();
      }
      this.sentimentChart = new Chart(this.sentimentCanvas.nativeElement, {
        type: 'scatter',
        data: {
          datasets: [
            {
              cubicInterpolationMode: 'monotone',
              data: chartData,
              showLine: true,
              fill: false,
              borderDash: [4, 3],
              borderWidth: 1,
              borderColor: function (context) {
                const chart = context.chart;
                const { ctx, chartArea } = chart;

                if (!chartArea) {
                  return;
                }
                return self.getGradient(ctx, chartArea, self);
              },
            },
          ],
        },
        options: {
          interaction: {
            intersect: false,
            axis: 'x',
            mode: 'nearest',
          },
          responsive: true,
          elements: {
            point: {
              radius: 0,
            },
          },
          maintainAspectRatio: false,
          scales: {
            y: {
              display: false,
              max: 1.5,
              min: -1.5,
              beginAtZero: true,
              ticks: {
                stepSize: 0.1,
                autoSkip: false,
              },
            },
            x: {
              max: durationMS,
              display: false,
              ticks: {
                autoSkip: false,
              },
            },
          },
          plugins: {
            tooltip: {
              enabled: false,
              position: 'nearest',
              external: this.externalTooltipHandler,
            },
            legend: {
              display: false,
            },
          },
        },
      });
    }
  }

  getOrCreateTooltip = chart => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.borderRadius = '3px';
      tooltipEl.style.color = 'white';
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(-50%, 0)';
      tooltipEl.style.transition = 'all .1s ease';

      const table = document.createElement('table');
      table.style.margin = '0px';

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  externalTooltipHandler = context => {
    const { chart, tooltip } = context;
    const tooltipEl = this.getOrCreateTooltip(chart);
    let data = chart.data.datasets[0].data;
    let index = tooltip.dataPoints[0].dataIndex;
    let sentimentValue = data[index].y;

    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    if (index === data.length - 1) {
      tooltipEl.style.opacity = 0;
      return;
    }

    if (tooltip.body) {
      const bodyLines = tooltip.body.map(b => b.lines);
      const tableBody = document.createElement('tbody');
      bodyLines.forEach((body, i) => {
        const tr = document.createElement('tr');
        tr.style.backgroundColor = 'inherit';
        tr.style.borderWidth = '0';

        const td = document.createElement('td');
        td.style.borderWidth = '0';
        const iElement = document.createElement('i');
        iElement.className = 'fal fa-tired';
        td.appendChild(this.createSentimentTooltip(sentimentValue));

        tr.appendChild(td);
        tableBody.appendChild(tr);
      });

      const tableRoot = tooltipEl.querySelector('table');

      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }

      tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    tooltipEl.style.opacity = 1;
    tooltipEl.style.index = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
  };

  createSentimentTooltip(value: number): HTMLDivElement {
    const div = document.createElement('div');
    let icon = document.createElement('i');
    icon.style.paddingRight = '5px';
    let span = document.createElement('span');

    if (value > 0) {
      icon.className = 'far fa-smile';
      span.textContent = this.localizationService.instant('Conversation::Analysis:Positive');
    } else if (value == 0) {
      icon.className = 'far fa-meh';
      span.textContent = this.localizationService.instant('Conversation::Analysis:Neutral');
    } else if (value < 0) {
      icon.className = 'far fa-frown';
      span.textContent = this.localizationService.instant('Conversation::Analysis:Negative');
    }

    div.appendChild(icon);
    div.appendChild(span);
    return div;
  }
}
