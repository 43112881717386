import { LocalizationService } from '@abp/ng.core';
import { Component, Input, OnInit } from '@angular/core';
import { CallDirection } from 'src/core/models/generic-lookup-type/call/call-direction.glt';

@Component({
  selector: 'ca-call-direction-icon',
  templateUrl: './call-direction-icon.component.html',
  styleUrls: ['./call-direction-icon.component.scss', '../conversation-icons.scss'],
})
export class CallDirectionIconComponent implements OnInit {
  @Input()
  directionId: number | null;

  get iconCls(): string {
    let result = '';

    switch (this.directionId) {
      case CallDirection.Inbound:
        result = 'fas fa-sign-in-alt';
        break;
      case CallDirection.inboundTransfer:
        result = 'fad fa-right-left';
        break;
      case CallDirection.outbound:
        result = 'fas fa-sign-out-alt';
        break;
      case CallDirection.outboundTransfer:
        result = 'fad fa-right-left fa-rotate-180';
        break;
      case CallDirection.internal:
        result = 'far fa-repeat-alt';
        break;
      default:
        result = 'fas fa-question';
    }

    return result;
  }

  get tooltip(): string {
    let result = '';

    switch (this.directionId) {
      case CallDirection.Inbound:
        result = `${this.localizationService.instant(
          'Conversation::CallDirection'
        )}: ${this.localizationService.instant('GenericLookup::Call.Direction.Inbound')}`;
        break;
      case CallDirection.inboundTransfer:
        result = `${this.localizationService.instant(
          'Conversation::CallDirection'
        )}: ${this.localizationService.instant('GenericLookup::Call.Direction.InboundTransfer')}`;
        break;
      case CallDirection.outbound:
        result = `${this.localizationService.instant(
          'Conversation::CallDirection'
        )}: ${this.localizationService.instant('GenericLookup::Call.Direction.Outbound')}`;
        break;
      case CallDirection.outboundTransfer:
        result = `${this.localizationService.instant(
          'Conversation::CallDirection'
        )}: ${this.localizationService.instant('GenericLookup::Call.Direction.OutboundTransfer')}`;
        break;
      case CallDirection.internal:
        result = `${this.localizationService.instant(
          'Conversation::CallDirection'
        )}: ${this.localizationService.instant('GenericLookup::Call.Direction.Internal')}`;
        break;
      default:
        result = `${this.localizationService.instant(
          'Conversation::CallDirection'
        )}: ${this.localizationService.instant('Query::Unknown')}`;
    }

    return result;
  }

  constructor(private localizationService: LocalizationService) {}

  ngOnInit(): void {}
}
